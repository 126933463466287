<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list">
    <div v-if="showStaticTab" class="profile-mobile-view market-select-mobile-view">
        <!-- <v-select2 style="margin-top: 8px;margin-bottom: 10px;" :v-model="current_category_list" :placeholder="setCurrentMarket()" :clearable="false" label="label" :searchable="false" :options="current_category_list" v-if="current_category_list" @input="setSelected"></v-select2> -->
        <div class="select-with-filter">
            <div v-if="!current_category_list" class="v-select-loading">
                <div class="text-center w100-w">
                    <div class="widget-loading"></div>
                </div>
            </div>
            <v-select v-if="current_category_list && showStaticTab" v-model="current_category_list"  @input="setSelected" class="profile-mobile-view-select" :items="current_category_list" item-text="label"  item-value="id" :label="setCurrentMarket()" outlined></v-select>
            <i v-if="!stock_hidden" class="uil uil-sliders-v-alt select-with-filter-icons" @click="showFilter = !showFilter"></i>
        </div>
        <v-select v-if="!stock_hidden && showFilter" return-object class="profile-mobile-view-select" item-text="name" v-model="default_filter_flow" :items="filter_flow" label=" بازار معاملاتی " @input="setStockFilterFlow" outlined style="margin-top: 5px !important;"></v-select>
        <v-select v-if="!stock_hidden && showFilter" return-object class="profile-mobile-view-select" item-text="name" v-model="defaultFilter" :items="filter" label=" مرتب سازی " @input="filterStock" outlined style="margin-top: 5px !important;"></v-select>
    </div>
    <MarketDynamic :parent_category_list="category_list" :is_home="is_home" v-if="category_list"></MarketDynamic>
    
 
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
// dropdown_off
</style>

<script>
// این ویو برای ماژول بازار بخش داینامیکش مورد استفاده قرار میگیرد
import MarketDynamic from "@/components/Mobile/Market/Dynamic";
import vSelect2 from 'vue-select';
Vue.component('v-select2', vSelect2);

export default {
    name: "marketDynamicPage",
    props: ['child_category_list', 'static_market'],
    components: {
        MarketDynamic,
    },
    data: function () {
        return {
            showFilter:false,
            category_list: null,
            current_category: null,
            defSelected: [],
            stock_list: ['stocks-bourse', 'stocks-stock', 'stocks-bond', 'stocks-future', 'stocks-option', 'stocks-fund',
                        'stocks-bourse-flow-1', 'stocks-bourse-flow-2', 'stocks-bourse-flow-100', 'stocks-stock-main', 
                        'stocks-stock-warrant', 'stocks-stock-closedassets', 'stocks-bond-main', 'stocks-mortgageloan-main', 'stocks-future-main',
                        'stocks-option-main', 'stocks-fund-main'],
            stock_hidden: true,
            is_home: false,
            defaultFilter: this.$store.state.stockFilter,
            default_filter_flow: this.$store.state.stockFilterFlow,
            filter_flow: [
            {
                name: "همه",
                flow: 0,
            },
                        {
                name: "بورس",
                flow: 1,
            },
                        {
                name: "فرابورس",
                flow: 2,
            },
                        {
                name: "بورس کالا",
                flow: 100,
            },
            ],
            filter: [
            {
                name: "آخرین قیمت",
                sort: 1,
                value: "info.last_trade.PDrCotVal"
            },
            {
                name: "قیمت پایانی",
                sort: 2,
                value: "info.last_price.PClosing"
                
            },
            {
                name: "نوسان آخرین قیمت",
                sort: 19,
                value: "info.last_trade.last_change_percentage"
            },
            {
                name: "نوسان قیمت پایانی",
                sort: 3,
                value: "info.last_price.closing_change_percentage"
            },
            {
                name: "تعداد معامله",
                sort: 4,
                value: "trades.ZTotTran"
            },
            {
                name: "حجم معامله",
                sort: 5,
                value: "trades.QTotTran5J"
            },
            {
                name: "ارزش معامله",
                sort: 6,
                value: "trades.QTotCap"
            },
            {
                name: "ارزش بازار",
                sort: 20,
                value: "trades.arzesh_bazar"
            },
            {
                name: "ارزش روز دارایی ها",
                sort: 23,
                value: "etc.arzesh_rooz_darayi"
            },
            {
                name: "ارزش دفتری دارایی ها",
                sort: 30,
                value: "etc.arzesh_daftari_darayi"
            },
            {
                name: "آخرین سرمایه",
                sort: 24,
                value: "etc.akharin_sarmaye"
            },
            {
                name: "بدهی ها",
                sort: 26,
                value: "etc.bedehi"
            },
            {
                name: "حقوق صاحبان سهام",
                sort: 25,
                value: "etc.hoghogh_saheban_saham"
            },
            {
                name: "پیش بینی درآمد",
                sort: 27,
                value: "etc.pishbini_daramad"
            },
            {
                name: "سود خالص TTM",
                sort: 31,
                value: "etc.sood_khales_ttm"
            },
            {
                name: "P/E",
                sort: 21,
                value: "info.pe"
            },
            {
                name: "P/B",
                sort: 22,
                value: "info.pb"
            },
            {
                name: "P/S",
                sort: 29,
                value: "info.ps"
            },
            {
                name: "حجم خرید حقیقی",
                sort: 8,
                value: "trades.buy_and_sell.Buy_I_Volume"
            },
            {
                name: "حجم خرید حقوقی",
                sort: 7,
                value: "trades.buy_and_sell.Buy_N_Volume"
            },
            {
                name: "حجم فروش حقیقی",
                sort: 10,
                value: "trades.buy_and_sell.Sell_I_Volume"
            },
            {
                name: "حجم فروش حقوقی",
                sort: 9,
                value: "trades.buy_and_sell.Sell_N_Volume"
            },
            // {
            //     name: "تعداد خرید",
            //     sort: 11
            // },
            // {
            //     name: "حجم خرید",
            //     sort: 12
            // },
            // {
            //     name: "قیمت خرید",
            //     sort: 13
            // },
            // {
            //     name: "تعداد فروش",
            //     sort: 14
            // },
            // {
            //     name: "حجم فروش",
            //     sort: 15
            // },
            // {
            //     name: "قیمت فروش",
            //     sort: 16
            // },
            // {
            //     name: "تغییر مالکیت حقوقی بورس تهران",
            //     sort: 17
            // },
            // {
            //     name: "تاثیر در شاخص",
            //     sort: 18
            // },
            ]
            }
    },
    computed: {
        showStaticTab() {
            return this.$store.state.showStaticTab;
        },
        current_category_list() {
            if (this.$route.params.market_id == 'all') {
                return false;
            }
            if (this.$store.state.currentMarketLvl == 3) {
                return this.$store.state.marketLvl3;
            } else if (this.$store.state.currentMarketLvl == 4) {
                return this.$store.state.marketLvl3;
            }
            return false;
        },
    },
    beforeDestroy () {
        this.$root.$off('update-stock-filter');
        this.$root.$off('update-stock-filter-flow');
    },
    mounted() {
        // this.$store.commit('setStockFilter', this.defaultFilter.sort);
        this.is_home = !window.location.href.includes('list');
        if (this.stock_list.includes(this.$route.params.market_id)) {
            this.stock_hidden = false;
        }
        this.getCategoryList('all');
    },
    methods: {
        setCurrentMarket() {
            var placeholder = this.$store.state.marketLvl3.find(({ id }) => id === this.$route.params.market_id);
            if (placeholder) {
                return placeholder.label
            }
            return '';
        },
        filterStock(value) {
            this.$store.commit('setStockFilter', value);
            this.$root.$emit('update-stock-filter');
        },
        setStockFilterFlow(value) {
            this.$store.commit('setStockFilterFlow', value);
            this.$root.$emit('update-stock-filter-flow');
        },
        setSelected(value) {
            this.$router.push({name: 'MarketList' , params: { market_id: value}});
        },
        // این متد با توجه به آرگومان ورود لیست بازار ها و یا دسته بندی ها را برمیگرداند
        getCategoryList(select_opt) {
            if (typeof this.static_market === 'undefined' && this.$route.params.market_id != 'all') {
                this.$helpers
                    .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
                    .then((api_response) => {
                            this.category_list = api_response.data.response.detail.items;
                    });
            } else {
                if (typeof this.static_market === 'undefined') {
                    this.category_list = this.$store.state.marketState;
                    this.category_list = this.$store.state.marketLvl2;
                } else {
                    this.$store.commit('setMarketLvl2', this.static_market);
                    this.category_list = this.static_market;
                }
            }
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    }
};
</script>
